import { get } from "lodash";
import Toaster from "../components/ui/toaster";
import ServerCall from "../serverCall";

const serverCall = ServerCall();
const login = async (userSignInRequest) => {
  const { email, password } = userSignInRequest;

  try {
    const response = await serverCall.customAxios.post(
      `/auth/adminLogin?email=${email}&password=${password}`
    );
    console.log("ressssssss", response);
    const data = get(response, "data", null);

    if (data) {
      const email = data.data.email;
      const name = data.data.name;
      const id = data.data._id;
      const token = data.token;
      const isLoggedIn = true;

      const userProfile = {
        token,
        email,
        name,
        id,
        isLoggedIn,
      };
      localStorage.setItem("raver-admin", JSON.stringify(userProfile));
    }

    Toaster("success", "User login successfully!");
    return response;
  } catch (error) {
    Toaster("error", error.response.data.message);
  }
};

const AuthenticationService = {
  login,
};

export default AuthenticationService;
