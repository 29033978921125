import React, { useEffect, useState } from "react";
import { Card } from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import {
  AlertCircle,
  Check,
  CircleCheck,
  CirclePlus,
  Search,
} from "lucide-react";
import { Button } from "../../components/ui/button";
import UserTable from "./UserTable";
import { useNavigate } from "react-router";
import UserService from "../../services/user.service";
import EventService from "../../services/event.service";
import Toaster from "../../components/ui/toaster";
import CsvModal from "../../components/CsvModal";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

const User = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [fileUploadError, setFileUploadError] = useState([]);

  const handelSingle = () => {
    navigate(`/events/add`);
  };

  const getAllUser = async () => {
    setIsLoading(true);
    try {
      const response = await EventService.getAllEvent(
        page,
        limit,
        selectedStatus === "all" ? "" : selectedStatus,
        searchText,
        selectedType === "all" ? "" : selectedType
      );
      const result = response.data.data;

      const sortedData = result?.sort((a, b) => {
        const dateA = a.createdAt ? new Date(a.createdAt) : -Infinity;
        const dateB = b.createdAt ? new Date(b.createdAt) : -Infinity;
        return dateB - dateA; // Sort in descending order (newest first)
      });

      console.log(sortedData);
      setData(sortedData);
      setTotalData(response.data.total);
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUser();
  }, [page, limit, searchText, selectedStatus, selectedType]);

  const handleStatusChange = async (e) => {
    setSelectedStatus(e);
  };
  const handleTypeChange = async (e) => {
    setSelectedType(e);
  };

  const acceptAll = async () => {
    try {
      const response = await EventService?.acceptAll();
      if (response) {
        Toaster("success", "All Event Status Change in accept Successfully");
        getAllUser();
      }
    } catch (error) {
      Toaster("error", error.response.data.message || "Something went wrong");
    }
  };

  return (
    <div>
      {fileUploadError.length > 0 && (
        <div className="flex items-center text-red-500 bg-red-50 p-3 gap-1 rounded-md mb-3">
          <AlertCircle className="mr-2" size={20} />
          {`This ${
            fileUploadError.length > 1 ? "titles" : "title"
          } is not created because of mismatch type of`}
          <span className="font-bold">{fileUploadError.join(", ")}</span>
        </div>
      )}
      <Card>
        <h3 className="text-2xl font-semibold mb-3">Event Data</h3>
        <div className="flex max-sm:flex-col items-center justify-end gap-2 mb-4">
          <div className="flex gap-3 justify-start w-full">
            <div className="relative">
              <Input
                className="sm:max-w-96 ps-8 w-full"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div className="absolute top-1/2 -translate-y-1/2 left-3">
                <Search className="text-muted-foreground" size={16} />
              </div>
            </div>

            <Select
              name="status"
              value={selectedStatus}
              onValueChange={(value) => {
                handleStatusChange(value);
              }}
            >
              <SelectTrigger className={`w-[130px]`}>
                <SelectValue placeholder="Select Status" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="all">All</SelectItem>
                  <SelectItem value="pending">Pending</SelectItem>
                  <SelectItem value="accept">Accept</SelectItem>
                  <SelectItem value="reject">Reject</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
            <Select
              name="type"
              value={selectedType}
              onValueChange={(value) => {
                handleTypeChange(value);
              }}
            >
              <SelectTrigger className={`w-[130px]`}>
                <SelectValue placeholder="Select Type" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="all">All</SelectItem>
                  <SelectItem value="club">Club</SelectItem>
                  <SelectItem value="concert">Concert</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>

          {/* <div className="flex items-center gap-3 max-sm:w-full"> */}
          <div className="flex gap-2">
            <Button className="flex gap-2" onClick={acceptAll}>
              <CircleCheck size={18} />
              <span>Accept All Event</span>
            </Button>
            <CsvModal
              fileUploadError={fileUploadError}
              setFileUploadError={setFileUploadError}
            />
          </div>
          {/* <AddModal /> */}
          {/* </div> */}
        </div>
        <div>
          <UserTable
            data={data}
            totalData={totalData}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            getAllUser={getAllUser}
            isLoading={isLoading}
          />
        </div>
      </Card>
    </div>
  );
};

export default User;
