import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { Button } from "./ui/button";
import {
  CirclePlus,
  ImageUp,
  FileSpreadsheet,
  AlertCircle,
} from "lucide-react";
import { DropdownMenuSeparator } from "./ui/dropdown-menu";
import { RiDeleteBin6Fill } from "react-icons/ri";
import Papa from "papaparse";
import EventService from "../services/event.service";
import Toaster from "./ui/toaster";

const CsvModal = ({ fileUploadError, setFileUploadError }) => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLabelVisible, setIsLabelVisible] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [fileDetails, setFileDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    processCSV(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files[0];
    processCSV(files);
  };

  const processCSV = (file) => {
    setFileDetails(file);
    if (file) {
      // Validate file type
      const validTypes = ["text/csv", "application/csv"];

      // Check file extension and MIME type
      const isValidFileType =
        validTypes.includes(file.type) ||
        file.name.toLowerCase().endsWith(".csv");

      // Optional: Check file size (e.g., limit to 10MB)
      const isValidFileSize = file.size <= 10 * 1024 * 1024; // 10MB

      if (!isValidFileType) {
        setErrorMessage("Please upload only CSV files");
        setSelectedFile(null);
        return;
      }

      if (!isValidFileSize) {
        setErrorMessage("File size should not exceed 10MB");
        setSelectedFile(null);
        return;
      }

      // File is valid
      setSelectedFile(file);
      setIsLabelVisible(false);
      setErrorMessage("");
    }

    const fileType = file.name.split(".").pop().toLowerCase();
    if (fileType !== "csv") {
      return;
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append("csvFile", selectedFile);
        const response = await EventService.uploadGoogleSheet(formData);
        console.log("file:::::;", response.data);

        setFileUploadError(response?.data?.notCreateDataTitle);

        // Example of reading file contents
        const reader = new FileReader();
        reader.onload = (e) => {
          const csvText = e.target.result;
          // Process CSV text
          console.log(csvText);
        };
        reader.readAsText(selectedFile);
        setIsDialogOpen(false);
        setSelectedFile(null);

        resetUpload();
        Toaster("success", response?.data?.message);
      } catch (error) {
        Toaster("error", error.response.data.message);
      } finally {
        setTimeout(() => {
          setFileUploadError([]);
        }, 5000);
      }
    }
  };

  const resetUpload = () => {
    setSelectedFile(null);
    setIsLabelVisible(true);
    setErrorMessage("");
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset file input
    }
  };

  return (
    <div>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogTrigger asChild>
          <Button className="sm:w-36 w-full flex items-center gap-1.5">
            <CirclePlus size={18} />
            Add CSV
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px] w-[calc(100vw-10vw)]">
          <DialogHeader>
            <DialogTitle>Add CSV File</DialogTitle>
          </DialogHeader>
          <DropdownMenuSeparator />

          {/* Error Message */}
          {errorMessage && (
            <div className="flex items-center text-red-500 bg-red-50 p-3 rounded-md">
              <AlertCircle className="mr-2" size={20} />
              {errorMessage}
            </div>
          )}

          {/* File Upload Area */}
          {isLabelVisible && (
            <label
              htmlFor="Upload"
              className="border border-dashed border-primary rounded-md cursor-pointer"
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => handleDrop(e)}
            >
              <div className="min-h-40 w-full flex items-center justify-center p-6">
                <div className="text-center flex items-center justify-center flex-col">
                  <FileSpreadsheet size={48} className="text-primary mb-4" />
                  <h1 className="text-center text-xl lg:text-2xl text-black/60 font-semibold mb-4">
                    Upload CSV File
                  </h1>
                  <Button
                    type="button"
                    onClick={handleButtonClick}
                    className="flex items-center justify-center gap-2"
                  >
                    <ImageUp size={18} />
                    Browse File
                  </Button>
                </div>
              </div>
            </label>
          )}

          {/* Selected File Display */}
          {selectedFile && (
            <div className="min-h-[202px] flex flex-col items-center justify-center gap-4 p-4 bg-gray-50 rounded-md">
              <div className="flex items-center">
                <FileSpreadsheet className="mr-2 text-primary" size={24} />
                <span>{selectedFile.name}</span>
              </div>
              <div
                className="rounded-full bg-destructive h-10 w-10 flex items-center justify-center cursor-pointer"
                size="sm"
                onClick={resetUpload}
              >
                <RiDeleteBin6Fill
                  size={18}
                  className="text-destructive text-white duration-200"
                />
              </div>
            </div>
          )}

          {/* Hidden file input */}
          <input
            type="file"
            id="Upload"
            accept=".csv,text/csv,application/vnd.ms-excel"
            hidden
            ref={fileInputRef}
            onChange={handleFileChange}
          />

          <div className="flex items-center justify-between w-full gap-4">
            {/* <Button
              type="submit"
              className="w-full sm:w-40"
              variant="destructive"
            >
              Cancel
            </Button> */}
            <Button
              type="submit"
              className="w-full"
              // className="w-full sm:w-40"
              onClick={handleUpload}
              disabled={!selectedFile}
            >
              Upload
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CsvModal;
