import React from "react";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from "./ui/pagination";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

const Paginations = ({
  total,
  page,
  handleChange,
  dataPerPage,
  handleDataPerPage,
  PAGE_SIZES,
}) => {
  const totalPages = Math.ceil(total / dataPerPage);

  const pageNumbers = [];

  // Always add first page
  pageNumbers.push(1);

  // Add ellipsis if current page is not 1 or 2
  if (page > 2) {
    pageNumbers.push("...");
  }

  // Add current page if it's not 1 or the last page
  if (page !== 1 && page !== totalPages) {
    pageNumbers.push(page);
  }

  // Add ellipsis if current page is not the last or second to last
  if (page < totalPages - 1) {
    pageNumbers.push("...");
  }

  // Always add last page if it's not the same as the first page
  if (totalPages > 1) {
    pageNumbers.push(totalPages);
  }

  return (
    <>
      <div className="flex justify-between items-center flex-col md:flex-row">
        <div className="flex gap-5 items-center lg:whitespace-nowrap">
          <p className="text-sm text-muted-foreground">
            Showing {(page - 1) * dataPerPage + 1} to{" "}
            {Math.min(page * dataPerPage, total)} of {total} entries
          </p>
          <Select onValueChange={handleDataPerPage}>
            <SelectTrigger className="w-20">
              <SelectValue placeholder="10" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup className="w-20">
                {PAGE_SIZES.map((item, index) => {
                  return (
                    <SelectItem className="pl-4" key={index} value={item}>
                      {item}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <Pagination className="justify-end">
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                type="button"
                onClick={() => handleChange("Previous", page)}
                className="cursor-pointer"
              />
            </PaginationItem>
            {pageNumbers.map((pageNumber, index) => (
              <li key={index}>
                {pageNumber === "..." ? (
                  <span className="px-2 py-2">...</span>
                ) : (
                  <button
                    type="button"
                    className={`text-xs font-semibold px-3.5 py-2 rounded-md transition ${
                      page === pageNumber
                        ? "bg-transparent text-blue border border-primary !bg-primary hover:text-accent-foreground"
                        : "bg-white-light text-dark hover:text-blue hover:text-accent-foreground border hover:border-primary border-input"
                    }`}
                    onClick={() => handleChange("", pageNumber)}
                  >
                    {pageNumber}
                  </button>
                )}
              </li>
            ))}
            <PaginationItem>
              <PaginationNext
                type="button"
                onClick={() => handleChange("Next", page)}
                className="cursor-pointer"
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
    </>
  );
};

export default Paginations;
