import ServerCall from "../serverCall";

const serverCall = ServerCall();

const getReport = (body) => {
  const response = serverCall.customAxios.post(`/admin/getReport`, body);
  return response;
};

const blockUser = (userId) => {
  try {
    const response = serverCall.customAxios.get(
      `/admin/block/reportUser?userId=${userId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
const unBlockUser = (userId) => {
  try {
    const response = serverCall.customAxios.get(
      `/admin/unblock/reportUser?userId=${userId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const ReportService = {
  getReport,
  blockUser,
  unBlockUser,
};

export default ReportService;
