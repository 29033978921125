import React from "react";
import { LayoutGrid, LogOut, Settings, User } from "lucide-react";

import { Button } from "../ui/button";
import { Tooltip, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import LogoIcon from "../../assets/images/logo-icon.svg";

import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { useNavigate } from "react-router";
import { setLoggedIn, setToken } from "../../store/slice/auth";
import { useDispatch } from "react-redux";

export function UserNav() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const raverStore = localStorage.getItem("raver-admin");
  const data = JSON.parse(raverStore);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(setLoggedIn(false));
    dispatch(setToken(""));
    navigate("/login");
    window.location.reload();
  };

  return (
    <DropdownMenu>
      <TooltipProvider disableHoverableContent>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                className="relative h-8 w-8 rounded-full"
              >
                <Avatar className="h-8 w-8">
                  <AvatarImage
                    src={LogoIcon}
                    alt="Avatar"
                    className="w-full h-full p-1.5"
                  />
                  {/* <AvatarFallback className="bg-transparent">JD</AvatarFallback> */}
                </Avatar>
              </Button>
            </DropdownMenuTrigger>
          </TooltipTrigger>
          {/* <TooltipContent side="bottom">Profil</TooltipContent> */}
        </Tooltip>
      </TooltipProvider>

      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{data?.name}</p>
            <p className="text-xs leading-none text-muted-foreground">
              {data?.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {/* <DropdownMenuGroup>
          <DropdownMenuItem className="hover:cursor-pointer" asChild>
            <a href="/account" className="flex items-center">
              <Settings className="w-4 h-4 mr-3 text-muted-foreground" />
              Setting
            </a>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator /> */}
        <DropdownMenuItem
          className="cursor-pointer text-destructive hover:!text-destructive"
          onClick={() => handleLogout()}
        >
          <LogOut className="w-4 h-4 mr-3 text-destructive" />
          Logout
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
