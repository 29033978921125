import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "../lib/utils";
import { Button } from "../components/ui/button";
import { Calendar } from "../components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { Label } from "./ui/label";

export function DateTimePicker({ formik, date, setDate }) {
  useEffect(() => {
    formik.setFieldValue("date", date);
  }, [date]);
  // Create minutes array
  const minutesArray = Array.from({ length: 60 }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  );

  const Hours = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  return (
    <div className="flex max-sm:flex-col items-center max-sm:gap-4 gap-1">
      <div className="w-full grid gap-2">
        <Label>
          Date <sup className="text-destructive">*</sup>
        </Label>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "w-full justify-start text-left font-normal",
                !date && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4 text-black/60" />
              {date ? format(date, "PPP") : <span>Pick a date</span>}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={date}
              onSelect={setDate}
              initialFocus
              formik={formik}
            />
          </PopoverContent>
        </Popover>
      </div>

      <div className="flex items-center gap-1 max-sm:w-full">
        <div className="grid gap-2 max-sm:w-full">
          <Label>
            Hours <sup className="text-destructive">*</sup>
          </Label>
          <Select
            onValueChange={(value) => formik.setFieldValue("hour", value)}
            value={formik.values.hour}
          >
            <SelectTrigger className="!w-full sm:!w-24">
              <SelectValue placeholder="Hour" />
            </SelectTrigger>
            <SelectContent className="max-h-48">
              {Hours.map((hour) => (
                <SelectItem key={hour} value={hour}>
                  {hour}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="grid gap-2 max-sm:w-full">
          <Label>
            Min <sup className="text-destructive">*</sup>
          </Label>
          <Select
            onValueChange={(value) => formik.setFieldValue("minute", value)}
            value={formik.values.minute}
          >
            <SelectTrigger className="!w-full sm:!w-24">
              <SelectValue placeholder="Min" />
            </SelectTrigger>
            <SelectContent className="max-h-48">
              {minutesArray.map((minute) => (
                <SelectItem key={minute} value={minute}>
                  {minute}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="grid gap-2 max-sm:w-full">
          <Label>
            AM/PM <sup className="text-destructive">*</sup>
          </Label>
          <Select
            onValueChange={(value) => formik.setFieldValue("timeZone", value)}
            value={formik.values.timeZone}
          >
            <SelectTrigger className="!w-full sm:!w-24">
              <SelectValue placeholder="AM" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="AM">AM</SelectItem>
              <SelectItem value="PM">PM</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
}

export default DateTimePicker;
