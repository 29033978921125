import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { FaEye, FaRegEdit } from "react-icons/fa";
import { BiSolidEdit } from "react-icons/bi";
import Events from "../../assets/images/image.png";
import React, { useEffect, useState } from "react";
import Pagination from "../../components/Paginations";
import { ImCancelCircle } from "react-icons/im";
import { FiAlertCircle } from "react-icons/fi";
import { Check } from "lucide-react";
import { useNavigate } from "react-router";
import DeleteModal from "../../components/DeleteModal";
import moment from "moment";
import EventService from "../../services/event.service";
import Toaster from "../../components/ui/toaster";
import Loader from "../../assets/images/loader.gif";

const status = ["Accept", "Pending", "Reject"];

const UserTable = ({
  data,
  totalData,
  page,
  setPage,
  limit,
  setLimit,
  getAllUser,
  isLoading,
}) => {
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const navigate = useNavigate();

  const handleChange = (type, page) => {
    const total = totalData / limit;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page !== newPage) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page !== 1) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      if (page !== 1) {
        setPage(1);
      }
    } else if (type === "Last") {
      if (page !== newPage) {
        setPage(newPage);
      }
    } else {
      setPage(page);
    }
  };

  const handleDataPerPage = (e) => {
    setLimit(e);
  };

  const handalUpdate = (type, id) => {
    if (type === "edit") {
      navigate(`/events/${id}`);
    } else {
      navigate(`/events/view/${id}`);
    }
  };

  // Delete Data
  const handleDelete = async (id) => {
    try {
      const response = await EventService.deleteEvent(id);
      getAllUser();
      Toaster("success", "Event Deleted Successfully");
    } catch (error) {
      console.log("🚀 ~ handleDelete ~ error:", error);
    }
  };

  return (
    <>
      <div className="border rounded-md">
        <Table>
          <TableHeader>
            <TableRow className="whitespace-nowrap">
              <TableHead className="w-[60px] text-center">No.</TableHead>
              <TableHead className="text-center">Event</TableHead>
              <TableHead>Title</TableHead>
              <TableHead>Location</TableHead>
              <TableHead>Type</TableHead>
              <TableHead className="">Date-Time</TableHead>
              <TableHead className="">Status</TableHead>
              <TableHead className="w-[125px] text-center">Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={8} className="text-center">
                  <div className="flex justify-center items-center h-full">
                    <img src={Loader} alt="Loading..." className="w-12 h-12" />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {data.length > 0 ? (
                  <>
                    {data.map((invoices, index) => (
                      <TableRow key={index}>
                        <TableCell className="text-center">
                          {(page - 1) * limit + index + 1}
                        </TableCell>
                        <TableCell className="w-[85px]">
                          <div className="h-16 w-16 rounded-md overflow-hidden">
                            <img
                              src={invoices.eventUrl}
                              alt="Events"
                              className="w-full h-full object-cover"
                            />
                          </div>
                        </TableCell>
                        <TableCell className="min-w-52">
                          {invoices.title}
                        </TableCell>
                        <TableCell className="min-w-60">
                          {invoices.address}
                        </TableCell>
                        <TableCell className="min-w-60">
                          {invoices.type}
                        </TableCell>
                        {invoices?.startDate && invoices?.startTime ? (
                          <TableCell className="whitespace-nowrap">
                            {moment(invoices.startDate).format("DD/MM/YYYY") +
                              " " +
                              moment(invoices.startTime).format("hh:mm A")}
                          </TableCell>
                        ) : (
                          <TableCell className="whitespace-nowrap w-32">
                            --
                          </TableCell>
                        )}
                        <TableCell className="whitespace-nowrap w-32">
                          <div
                            className={`w-32 px-2 py-1 rounded-sm text-white flex items-center justify-center gap-2 capitalize ${
                              invoices.status === "accept"
                                ? "bg-green-600"
                                : invoices.status === "pending"
                                ? "bg-[#f29339]"
                                : "bg-red-500"
                            }`}
                          >
                            {invoices.status === "accept" ? (
                              <Check size={18} />
                            ) : invoices.status === "pending" ? (
                              <FiAlertCircle size={18} />
                            ) : (
                              <ImCancelCircle size={18} />
                            )}
                            {invoices.status}
                          </div>
                        </TableCell>
                        {/* <TableCell className="min-w-60">{invoices.status}</TableCell> */}

                        <TableCell>
                          <div className="flex items-center justify-center gap-2">
                            <div
                              className="h-7 w-7 border border-black hover:border-primary hover:bg-primary cursor-pointer flex items-center justify-center rounded-sm duration-200 group"
                              onClick={() =>
                                handalUpdate("edit", invoices?._id)
                              }
                            >
                              <BiSolidEdit
                                size={18}
                                className="text-black group-hover:text-white duration-200"
                              />
                            </div>
                            <div
                              className="h-7 w-7 border border-black hover:border-primary hover:bg-primary cursor-pointer flex items-center justify-center rounded-sm duration-200 group"
                              onClick={() =>
                                handalUpdate("view", invoices?._id)
                              }
                            >
                              <FaEye
                                size={18}
                                className="text-black group-hover:text-white duration-200"
                              />
                            </div>
                            <DeleteModal
                              content={invoices?.title}
                              id={invoices?._id}
                              handleDelete={handleDelete}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} className="text-center">
                      <div className="flex justify-center items-center h-full text-muted-foreground font-bold">
                        Events not Found
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="mt-5">
        <Pagination
          total={totalData}
          page={page}
          handleChange={handleChange}
          dataPerPage={limit}
          handleDataPerPage={handleDataPerPage}
          PAGE_SIZES={PAGE_SIZES}
        />
      </div>
    </>
  );
};

export default UserTable;
