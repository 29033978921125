import React, { useEffect, useState } from "react";
import { Card } from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import {
  AlertCircle,
  Check,
  CircleCheck,
  CirclePlus,
  Search,
} from "lucide-react";
import { Button } from "../../components/ui/button";
import { useNavigate } from "react-router";
import UserService from "../../services/user.service";
import EventService from "../../services/event.service";
import Toaster from "../../components/ui/toaster";
import CsvModal from "../../components/CsvModal";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import DashboardTable from "./Table";
import GenderPieChart from "./Piechart";
import DashboardService from "../..//services/dashbord.service";

const Dashboard = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getDashboardData = async () => {
    setIsLoading(true);
    try {
      const response = await DashboardService
        .getDashboardData
        /* page,
      limit
      selectedStatus === "all" ? "" : selectedStatus,
      searchText,
      selectedType === "all" ? "" : selectedType */
        ();
      setData(response?.data);
      setTotalData(response.data.total);
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, [page, limit /* , searchText, selectedStatus, selectedType */]);

  return (
    <div>
      <Card>
        <h3 className="text-2xl font-semibold mb-3">Dashboard</h3>
        <div className="grid grid-cols-1 md:grid-cols-[60%,40%] gap-4">
          <div className="border border-input rounded-lg p-6">
            <DashboardTable
              data={data}
              totalData={totalData}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              getDashboardData={getDashboardData}
              isLoading={isLoading}
            />
          </div>
          <div className="flex justify-center items-center border border-input rounded-lg p-4">
            <GenderPieChart data={data} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Dashboard;
