import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const GenderPieChart = ({ data }) => {
  const [series, setSeries] = useState([0, 0]);
  const options = {
    chart: {
      type: "pie",
      width: 380,
    },
    labels: ["Male", "Female"],
    legend: {
      position: "bottom", // Ensure legend is at the bottom for smaller screens as well
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  useEffect(() => {
    if (data && data.maleUser !== undefined && data.femaleUser !== undefined) {
      const series = [data.maleUser, data.femaleUser];
      setSeries(series);
    } else {
      setSeries([0, 0]); // Set default values in case data is missing or invalid
    }
  }, [data]);

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        width={380}
      />
    </div>
  );
};

export default GenderPieChart;
