import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import Router from "./router/router";
import { setLoggedIn, setToken } from "./store/slice/auth";
import { useEffect } from "react";

function App() {
  const dispatch = useDispatch();
  const raverStorageString = localStorage.getItem("raver-admin");
  const raverStorage = JSON.parse(raverStorageString);

  useEffect(() => {
    if (raverStorage && raverStorage.token) {
      setToken(localStorage.getItem("token"));
    }
    if (raverStorage && raverStorage.isLoggedIn) {
      dispatch(
        setLoggedIn(JSON.parse(raverStorage && raverStorage.isLoggedIn))
      );
    }
  }, []);

  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    const storedIsLoggedIn = raverStorage && raverStorage.isLoggedIn;
    dispatch(setLoggedIn(storedIsLoggedIn));
  }, [isLoggedIn]);

  const isLoggedin = isLoggedIn || (raverStorage && raverStorage.token);

  return (
    <>
      <Router isLoggedin={isLoggedin} />
    </>
  );
}

export default App;
