import { CalendarDays, LayoutDashboard } from "lucide-react";

export function getMenuList(pathname) {
  return [
    {
      groupLabel: "",
      menus: [
        {
          href: "/dashboard",
          label: "Dashboard",
          active: pathname.includes("/dashboard"),
          icon: LayoutDashboard,
          submenus: [],
        },
        {
          href: "/events",
          label: "Events",
          active: pathname.includes("/events"),
          icon: CalendarDays,
          submenus: [],
        },
        {
          href: "/reports",
          label: "Reports",
          active: pathname.includes("/reports"),
          icon: CalendarDays,
          submenus: [],
        },
      ],
    },
    // {
    //   groupLabel: "Contents",
    //   menus: [
    //     {
    //       href: "",
    //       label: "Posts",
    //       active: pathname.includes("/posts"),
    //       icon: SquarePen,
    //       submenus: [
    //         {
    //           href: "/posts",
    //           label: "All Posts",
    //           active: pathname === "/posts",
    //         },
    //         {
    //           href: "/posts/new",
    //           label: "New Post",
    //           active: pathname === "/posts/new",
    //         },
    //       ],
    //     },
    //   ],
    // },
  ];
}
