import ServerCall from "../serverCall";

const serverCall = ServerCall();

const getAllEvent = (page, size, status, search, type) => {
  const response = serverCall.customAxios.get(
    `/admin/events/all?page=${page}&size=${size}&status=${status}&search=${search}&eventType=${type}`
  );
  return response;
};

const getAllEventRequests = (page, size, search) => {
  const response = serverCall.customAxios.get(
    `/admin/events/getEventRequest?page=${page}&size=${size}&search=${search}`
  );
  return response;
};

const acceptAll = () => {
  try {
    const response = serverCall.customAxios.post(
      `/admin/events/allPending/statusChange?status=accept`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const uploadGoogleSheet = (body) => {
  const response = serverCall.customAxios.post(
    `/admin/events/uploadGoogleSheet`,
    body
  );
  return response;
};

const upadteEvent = (id, data) => {
  const response = serverCall.customAxios.patch(
    `/admin/events/update?id=${id}`,
    data
  );
  return response;
};

const updateEventStatus = (id, status) => {
  const response = serverCall.customAxios.patch(
    `/admin/events/updateEventRequest?id=${id}&status=${status}`
  );
  return response;
};

const getEventDetails = (id) => {
  const response = serverCall.customAxios.get(
    `/admin/events/getDetails?id=${id}`
  );
  return response;
};

const deleteEvent = (id) => {
  const response = serverCall.customAxios.delete(
    `/admin/events/delete?id=${id}`
  );
  return response;
};

const EventService = {
  getAllEvent,
  getEventDetails,
  deleteEvent,
  getAllEventRequests,
  updateEventStatus,
  upadteEvent,
  uploadGoogleSheet,
  acceptAll,
};

export default EventService;
