import React from "react";
import { PanelsTopLeft } from "lucide-react";

import { cn } from "../../lib/utils";
import { Button } from "../ui/button";
import { SidebarToggle } from "./sidebar-toggle";
import { Menu } from "./menu";
import { useSidebarToggle } from "../../hooks/use-sidebar-toggle";
import { useStore } from "zustand";
import LogoIcon from "../../assets/images/logo-icon.svg";
import LogoName from "../../assets/images/logo-name.svg";

export function Sidebar() {
  const sidebar = useStore(useSidebarToggle, (state) => state);

  if (!sidebar) return null;

  return (
    <aside
      className={cn(
        "fixed top-0 left-0 z-20 h-screen -translate-x-full lg:translate-x-0 transition-[width] ease-in-out duration-300",
        sidebar?.isOpen === false ? "w-[90px]" : "w-72"
      )}
    >
      <SidebarToggle isOpen={sidebar?.isOpen} setIsOpen={sidebar?.setIsOpen} />
      <div className="relative h-full flex flex-col py-4 overflow-y-auto shadow-md dark:shadow-zinc-800">
        <div className="flex items-center justify-center">
          <Button
            className={cn(
              "transition-transform ease-in-out duration-300 mb-1",
              sidebar?.isOpen === false ? "translate-x-1" : "translate-x-0"
            )}
            variant="link"
            asChild
          >
            <a href="" className="flex items-center gap-2">
              <div className="w-6 h-6">
                <img src={LogoIcon} alt="Icon" className="w-full h-full" />
              </div>
              {/* <img src={LogoIcon} alt="Icon" /> */}
              <div className="h-4">
                <img
                  src={LogoName}
                  className={cn(
                    "w-full h-full",
                    sidebar?.isOpen === false
                      ? "-translate-x-96 opacity-0 hidden"
                      : "translate-x-0 opacity-100"
                  )}
                  alt="Name"
                />
              </div>
            </a>
          </Button>
        </div>
        <Menu isOpen={sidebar?.isOpen} />
      </div>
    </aside>
  );
}
