import { ChevronLeft } from "lucide-react";

import { cn } from "../../lib/utils";
import { Button } from "../ui/button";
import { useLocation, useNavigate } from "react-router";

export function SidebarToggle({ isOpen, setIsOpen }) {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.pathname);
  return (
    <div className="invisible lg:visible absolute top-[12px] -right-[50px] z-20">
      {!(
        location.pathname === "/dashboard" ||
        location.pathname === "/events" ||
        location.pathname === "/reports"
      ) && (
        <Button
          // onClick={() => setIsOpen(!isOpen)}
          onClick={() => navigate(-1)}
          className="rounded-md w-8 h-8"
          variant="outline"
          size="icon"
        >
          <ChevronLeft
            className={cn(
              "h-4 w-4 transition-transform ease-in-out duration-700",
              isOpen === false ? "rotate-180" : "rotate-0"
            )}
          />
        </Button>
      )}
    </div>
  );
}
