import React, { useEffect, useRef, useState } from "react";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import DateTimePicker from "../../components/DateTimePicker";
import { Card } from "../../components/ui/card";
import { ImageUp, Trash2 } from "lucide-react";
import { Button } from "../../components/ui/button";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { useLocation, useNavigate, useParams } from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import EventService from "../../services/event.service";
import Toaster from "../../components/ui/toaster";
import moment from "moment";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import { Textarea } from "../../components/ui/textarea";

const UserUpdate = () => {
  const params = useParams();
  const location = useLocation();
  const eventID = location.state;
  const buttonText = eventID ? "Update" : "Submit";
  const navigate = useNavigate();

  const fileInputRef = useRef(null);
  const [date, setDate] = useState();
  const [isStartDate, setIsStartDate] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLabelVisible, setIsLabelVisible] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isFileUpdated, setIsFileUpdated] = useState(false);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsFileUpdated(true);
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setIsLabelVisible(false);
      // Set the file in Formik form values
      formik.setFieldValue("eventImg", file);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setIsLabelVisible(true);
    // Clear the event file in Formik
    formik.setFieldValue("event", null);
  };

  const handBack = () => {
    navigate(-1);
  };

  const validationSchema = Yup.object({
    eventImg: Yup.mixed().required("Please Enter Image"),
    title: Yup.string().required("Please Enter Title"),
    description: Yup.string().required("Please Enter Description"),
    address: Yup.string().required("Please Enter Address"),
    startDate:
      isStartDate && Yup.date().required("Start Date is required").nullable(),
  });

  const initialValues = {
    eventImg: null,
    title: "",
    description: "",
    address: null,
    startTime: null,
    endTime: null,
    startDate: null,
    endDate: null,
    latitude: "",
    longitude: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const {
      address,
      description,
      endDate,
      endTime,
      eventImg,
      startDate,
      startTime,
      title,
      longitude,
      latitude,
    } = values;
    setSubmitting(false);

    try {
      const formData = new FormData();
      formData.append("address", address);
      formData.append("description", description);
      formData.append("title", title);
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      endDate && formData.append("endDate", endDate);
      endTime && formData.append("endTime", endTime);
      startDate && formData.append("startDate", startDate);
      startTime && formData.append("startTime", startTime);
      isFileUpdated && formData.append("eventImg", eventImg);

      const response = await EventService.upadteEvent(params?.id, formData);
      Toaster("success", response.data.message);
      setIsFileUpdated(false);
    } catch (error) {
      Toaster("error", error.response.data.message);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const getEventDetails = async () => {
    try {
      const response = await EventService.getEventDetails(params.id);
      console.log("🚀 ~ getEventDetails ~ response:", response.data.data);
      const result = response.data.data;
      console.log(result.description);
      setSelectedImage(result.eventUrl);
      setIsLabelVisible(false);
      setSelectedStatus(result.status);
      formik.setFieldValue("eventImg", result.eventUrl);
      formik.setFieldValue("title", result.title);
      formik.setFieldValue("description", result.description);
      formik.setFieldValue("address", result.address);
      formik.setFieldValue("startTime", result.startTime);
      formik.setFieldValue("endTime", result.endTime);
      formik.setFieldValue("startDate", result.startDate);
      if (result.startDate) {
        setIsStartDate(true);
      }
      formik.setFieldValue("endDate", result.endDate);
      formik.setFieldValue("latitude", result.latitude);
      formik.setFieldValue("longitude", result.longitude);

      // formik.setFieldValue("location", result.address);
      // formik.setFieldValue("date", result.startDate);
      // formik.setFieldValue("hour", moment(result.startTime).format("hh"));
      // eventImg, title, description, address,  startTime ,endTime, startDate ,endDate

      // formik.setFieldValue("minute", moment(result.startTime).format("mm"));
      // formik.setFieldValue("timeZone", moment(result.startTime).format("A"));
      setDate(new Date(result.startDate));
    } catch (error) {
      Toaster("error", error.response.data.message);
    }
  };

  useEffect(() => {
    if (params.id !== "add") {
      getEventDetails();
    }
  }, [params.id]);

  const handleStatusChange = async (e) => {
    setSelectedStatus(e);
    try {
      await EventService.updateEventStatus(params?.id, e);
      Toaster("success", "Event Status Updated Successfully!");
    } catch (error) {
      Toaster("error", error.response.data.message);
    }
  };

  const onStartTimeChange = (time, timeString) => {
    formik.setFieldValue(
      "startTime",
      moment.utc(timeString, "h:mm A").valueOf()
    );
  };

  const onStartDateChange = (time, timeString) => {
    formik.setFieldValue("startDate", timeString);
  };

  const onEndTimeChange = (time, timeString) => {
    formik.setFieldValue("endTime", moment.utc(timeString, "h:mm A").valueOf());
    // formik.setFieldValue("endTime", moment.utc(new Date(timeString)).valueOf());
  };

  const onEndDateChange = (time, timeString) => {
    formik.setFieldValue("endDate", timeString);
    // formik.setFieldValue("endDate", moment.utc(new Date(timeString)).valueOf());
  };

  return (
    <div className="max-w-xl">
      <Card>
        <form className="grid gap-6" onSubmit={formik.handleSubmit}>
          <div className="grid gap-2">
            <Label>
              Event <sup className="text-destructive">*</sup>
            </Label>

            <div className="flex justify-end mb-2">
              <Select
                name="status"
                value={selectedStatus}
                onValueChange={(value) => handleStatusChange(value)}
              >
                <SelectTrigger
                  className={`${
                    selectedStatus === "accept"
                      ? "bg-[#00ab562d] hover:!bg-[#00ab565e]"
                      : selectedStatus === "pending"
                      ? "!bg-warning-dark-light hover:!bg-[#e2a13f63]"
                      : "bg-[#e7515b2f] hover:!bg-[#e7515b50]"
                  } w-[130px]`}
                >
                  <SelectValue placeholder="Select Status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem
                      value="pending"
                      className="bg-warning-dark-light hover:!bg-[#e2a13f63] mb-1"
                    >
                      Pending
                    </SelectItem>
                    <SelectItem
                      value="accept"
                      className="bg-[#00ab562d] hover:!bg-[#00ab565e] mb-1"
                    >
                      Accept
                    </SelectItem>
                    <SelectItem
                      value="reject"
                      className="bg-[#e7515b2f] hover:!bg-[#e7515b50]"
                    >
                      Reject
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            {/* Show label if isLabelVisible is true */}
            {isLabelVisible && (
              <label
                htmlFor="Upload"
                className="border border-dashed border-primary rounded-md cursor-pointer"
              >
                <div className="min-h-40 w-full flex items-center justify-center p-6">
                  <div className="text-center flex items-center justify-center flex-col">
                    <h1 className="text-center text-xl lg:text-2xl text-black/60 font-semibold">
                      Drag and drop to Upload your file
                    </h1>
                    <Button
                      type="button"
                      onClick={handleButtonClick}
                      className="flex items-center justify-center mt-4 gap-2"
                    >
                      <ImageUp size={18} className=" text-black" />
                      Browse File
                    </Button>
                  </div>
                </div>
              </label>
            )}

            {/* Hidden file input */}
            <input
              type="file"
              id="Upload"
              accept="image/png, image/jpeg"
              hidden
              ref={fileInputRef}
              onChange={handleFileChange}
            />

            {/* Display selected image */}
            {selectedImage && (
              <div className="flex flex-col items-center">
                <div className="flex max-h-40 items-center w-fit relative group">
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="w-full h-full rounded-md shadow-md group-hover:opacity-45 duration-200"
                  />
                  <div
                    onClick={handleRemoveImage}
                    className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-10 w-10 hidden group-hover:flex items-center justify-center bg-destructive rounded-full cursor-pointer"
                  >
                    <Trash2 size={20} className="text-white" />
                  </div>
                </div>
              </div>
            )}
            {formik.touched.eventImg && formik.errors.eventImg && (
              <div className="text-destructive text-sm">
                {formik.errors.eventImg}
              </div>
            )}
          </div>
          <div className="grid gap-2">
            <Label>
              Title <sup className="text-destructive">*</sup>
            </Label>
            <Input
              name="title"
              placeholder="Enter Title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.title && formik.errors.title && (
              <div className="text-destructive text-sm">
                {formik.errors.title}
              </div>
            )}
          </div>
          <div className="grid gap-2">
            <Label>
              Address <sup className="text-destructive">*</sup>
            </Label>
            <Input
              name="address"
              placeholder="Enter Address"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.address && formik.errors.address && (
              <div className="text-destructive text-sm">
                {formik.errors.address}
              </div>
            )}
          </div>

          <div className="grid gap-2">
            <Label>
              Description <sup className="text-destructive">*</sup>
            </Label>
            <Textarea
              name="description"
              placeholder="Enter Description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.description && formik.errors.description && (
              <div className="text-destructive text-sm">
                {formik.errors.description}
              </div>
            )}
          </div>
          <div>
            <div className="flex gap-3">
              {formik.values?.startDate && (
                <div className="w-full">
                  <Label>Start Date</Label>
                  <DatePicker
                    onChange={onStartDateChange}
                    value={dayjs(
                      `${moment
                        .utc(formik.values?.startDate)
                        .format("YYYY-MM-DD")}`,
                      "YYYY-MM-DD"
                    )}
                    defaultValue={dayjs(
                      `${moment
                        .utc(formik.values?.startDate)
                        .format("YYYY-MM-DD")}`,
                      "YYYY-MM-DD"
                    )}
                    name="startDate"
                    className="w-full"
                  />
                  {formik.touched.startDate && formik.errors.startDate && (
                    <div className="text-destructive text-sm">
                      {formik.errors.startDate}
                    </div>
                  )}
                </div>
              )}
              {formik.values?.startTime && (
                <div className="w-full">
                  <Label>Start Time</Label>
                  <TimePicker
                    use12Hours
                    format="h:mm A"
                    name="startTime"
                    className="w-full"
                    onChange={onStartTimeChange}
                    value={dayjs(
                      `${moment
                        .utc(formik.values?.startTime)
                        .format("h:mm A")}`,
                      "h:mm A"
                    )}
                    defaultValue={dayjs(
                      `${moment
                        .utc(formik.values?.startTime)
                        .format("h:mm A")}`,
                      "h:mm A"
                    )}
                  />
                </div>
              )}
              {/* <DateTimePicker
              date={date}
              setDate={setDate}
              value={formik.values.startDate}
              formik={formik}
            /> */}
              {/* {formik.touched.startDate && formik.errors.startDate && (
              <div className="text-destructive text-sm">
                {formik.errors.startDate}
              </div>
            )} */}
            </div>
          </div>

          <div>
            <div className="flex gap-3">
              {formik.values?.endDate && (
                <div className="w-full">
                  <Label>End Date</Label>
                  <DatePicker
                    onChange={onEndDateChange}
                    value={dayjs(
                      `${moment
                        .utc(formik.values?.endDate)
                        .format("YYYY-MM-DD")}`,
                      "YYYY-MM-DD"
                    )}
                    defaultValue={dayjs(
                      `${moment
                        .utc(formik.values?.endDate)
                        .format("YYYY-MM-DD")}`,
                      "YYYY-MM-DD"
                    )}
                    name="endDate"
                    className="w-full"
                  />
                </div>
              )}
              {formik.values?.endTime && (
                <div className="w-full">
                  <Label>End Time</Label>
                  <TimePicker
                    use12Hours
                    format="h:mm A"
                    name="endTime"
                    className="w-full"
                    onChange={onEndTimeChange}
                    value={dayjs(
                      `${moment.utc(formik.values?.endTime).format("h:mm A")}`,
                      "h:mm A"
                    )}
                    defaultValue={dayjs(
                      `${moment.utc(formik.values?.endTime).format("h:mm A")}`,
                      "h:mm A"
                    )}
                  />
                </div>
              )}
              {/* <DateTimePicker
              date={date}
              setDate={setDate}
              value={formik.values.startDate}
              formik={formik}
            /> */}
              {/* {formik.touched.startDate && formik.errors.startDate && (
              <div className="text-destructive text-sm">
                {formik.errors.startDate}
              </div>
            )} */}
            </div>
          </div>
          <div className="flex items-center justify-between gap-4 mt-10">
            <Button
              variant="destructive"
              className="max-w-40 w-full"
              type="button"
              onClick={handBack}
            >
              Cancel
            </Button>
            <Button
              className="max-w-40 w-full"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {buttonText}
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default UserUpdate;
