import ServerCall from "../serverCall";

const serverCall = ServerCall();

const getDashboardData = () => {
  try {
    const response = serverCall.customAxios.get(`/admin/dashBord`);
    return response;
  } catch (error) {
    throw error;
  }
};

const DashboardService = { getDashboardData };

export default DashboardService;
