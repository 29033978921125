import React from "react";
import { Navigate, useRoutes } from "react-router";
import { lazy } from "react";
import AdminPanelLayout from "../components/admin-panel/admin-panel-layout";
import Login from "../pages/authentication/Login";
import User from "../pages/user/User";
import UserUpdate from "../pages/user/UserUpdate";
import Account from "../pages/accout/Account";
import EventView from "../pages/user/EventView";
import EventReport from "../pages/report/EventReport";
import Dashboard from "../pages/dashboard";

const SuspenseLoader = (Component) => (props) =>
  (
    <React.Suspense fallback={<withLoader />}>
      <Component {...props} />
    </React.Suspense>
  );

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const routes = (isLoggedin) => [
  {
    path: "/",
    element: isLoggedin ? (
      <AdminPanelLayout />
    ) : (
      <RedirectionWrapper to="/login" />
    ),
    children: [
      {
        element: <RedirectionWrapper to="/dashboard" />,
        index: true,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/events",
        element: <User />,
      },
      {
        path: "/events/:id",
        element: <UserUpdate />,
      },
      {
        path: "/events/view/:id",
        element: <EventView />,
      },
      {
        path: "/reports",
        element: <EventReport />,
      },
      {
        path: "/account",
        element: <Account />,
      },
    ],
  },
  {
    path: "/login",
    element: !isLoggedin ? <Login /> : <RedirectionWrapper to="/events" />,
  },
];

// export default function Router() {
//   return useRoutes(routes());
// }
export default function Routes(props) {
  const { isLoggedin } = props;
  return useRoutes(routes(isLoggedin));
}
