import React, { useEffect, useRef, useState } from "react";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import DateTimePicker from "../../components/DateTimePicker";
import { Card } from "../../components/ui/card";
import { ImageUp, Pencil, Trash2 } from "lucide-react";
import { Button } from "../../components/ui/button";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { useNavigate, useParams } from "react-router";
import EventService from "../../services/event.service";
import Toaster from "../../components/ui/toaster";
import moment from "moment";
import Loader from "../../assets/images/loader.gif";
import { cn } from "../../lib/utils";

const EventView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState();
  const [data, setData] = useState([]);
  const [events, setEvents] = useState("");

  const getEventDetails = async () => {
    setIsLoading(true);
    try {
      const response = await EventService.getEventDetails(params.id);
      const result = response.data.data;
      setData(result);
      setDate(new Date(result.startDate));
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params.id !== "add") {
      getEventDetails();
    }
  }, [params.id]);

  const handleStatusChange = async (e) => {
    try {
      await EventService.updateEventStatus(params?.id, e);
      Toaster("success", "Event Status Updated Successfully!");
      getEventDetails();
    } catch (error) {
      Toaster("error", error.response.data.message);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="flex h-[calc(100vh-176px)] w-full justify-center items-center">
          <img src={Loader} alt="Loading..." className="w-12 h-12" />
        </div>
      ) : (
        <div className="max-w-xl">
          <Card>
            <div className="grid gap-2">
              <div className="flex justify-between mb-2">
                <Label className="font-bold text-lg">{data?.title}</Label>
                <div className="flex gap-3">
                  <Button
                    onClick={() => navigate(`/events/${params?.id}`)}
                    className="gap-2"
                    type="button"
                  >
                    <Pencil size={18} /> Edit
                  </Button>
                  <Select
                    name="status"
                    value={data?.status}
                    onValueChange={handleStatusChange}
                  >
                    <SelectTrigger
                      className={`${
                        data?.status === "accept"
                          ? "bg-[#00ab562d] hover:!bg-[#00ab565e]"
                          : data?.status === "pending"
                          ? "!bg-warning-dark-light hover:!bg-[#e2a13f63]"
                          : "bg-[#e7515b2f] hover:!bg-[#e7515b50]"
                      } w-[130px]`}
                    >
                      <SelectValue placeholder="Select Status" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem
                          value="pending"
                          className="bg-warning-dark-light hover:!bg-[#e2a13f63] mb-1"
                        >
                          Pending
                        </SelectItem>
                        <SelectItem
                          value="accept"
                          className="bg-[#00ab562d] hover:!bg-[#00ab565e] mb-1"
                        >
                          Accept
                        </SelectItem>
                        <SelectItem
                          value="reject"
                          className="bg-[#e7515b2f] hover:!bg-[#e7515b50]"
                        >
                          Reject
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <div className="flex flex-col items-center">
                <div className="flex max-h-40 items-center w-fit relative group mb-2">
                  <img
                    src={data?.eventUrl}
                    alt="Selected"
                    className="w-full h-full rounded-md shadow-md duration-200"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3 mt-3">
              {data?.createdBy?.name && (
                <div className="grid gap-2">
                  <Label>User Name</Label>
                  <Input
                    name="title"
                    placeholder="Enter Title"
                    disabled
                    value={data?.createdBy?.name}
                    className="mb-3"
                  />
                </div>
              )}
              {data?.createdBy?.phoneNumber && (
                <div className="grid gap-2">
                  <Label>Number</Label>
                  <Input
                    name="title"
                    placeholder="Enter Title"
                    disabled
                    value={data?.createdBy?.phoneNumber}
                    className="mb-3"
                  />
                </div>
              )}
            </div>

            <div className="grid gap-2">
              <Label>Description</Label>
              <textarea
                name="title"
                placeholder="Enter Title"
                disabled
                value={data?.description ? data?.description : "--"}
                className={cn(
                  "flex min-h-[80px] w-full rounded-md border border-input bg-input-bg px-3 py-2 text-sm placeholder:text-muted-foreground focus:border-primary focus:outline-none mb-3"
                )}
              />
            </div>
            <div className="grid gap-2">
              <Label>Address</Label>
              <Input
                name="location"
                placeholder="Enter Address"
                disabled
                value={`${data?.address}, ${data?.city}, ${data?.state}, ${data?.country}`}
                className="mb-3"
              />
            </div>

            <div className="grid grid-cols-2 gap-2">
              {data?.startDate && (
                <div>
                  <Label>Start Date</Label>
                  <Input
                    name="title"
                    placeholder="Enter Title"
                    disabled
                    value={`${moment
                      .utc(data?.startDate)
                      .format("DD/MM/YYYY")}`}
                    className="mb-3"
                  />
                </div>
              )}
              {data?.startTime && (
                <div>
                  <Label>Start Time</Label>
                  <Input
                    name="title"
                    placeholder="Enter Title"
                    disabled
                    value={`${moment.utc(data?.startTime).format("hh:mm A")}`}
                    className="mb-3"
                  />
                </div>
              )}
            </div>

            <div className="grid grid-cols-2 gap-2">
              {data?.endDate && (
                <div>
                  <Label>End Date</Label>
                  <Input
                    name="title"
                    placeholder="Enter Title"
                    disabled
                    value={`${moment.utc(data?.endDate).format("DD/MM/YYYY")}`}
                    className="mb-3"
                  />
                </div>
              )}
              {data?.endTime && (
                <div>
                  <Label>End Time</Label>
                  <Input
                    name="title"
                    placeholder="Enter Title"
                    disabled
                    value={`${moment.utc(data?.endTime).format("hh:mm A")}`}
                    className="mb-3"
                  />
                </div>
              )}
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default EventView;
