import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { FaEye, FaRegEdit } from "react-icons/fa";
import { BiSolidEdit } from "react-icons/bi";
import Events from "../../assets/images/image.png";
import React, { useEffect, useState } from "react";
import Pagination from "../../components/Paginations";
import { ImCancelCircle } from "react-icons/im";
import { FiAlertCircle } from "react-icons/fi";
import { Check } from "lucide-react";
import { useNavigate } from "react-router";
import DeleteModal from "../../components/DeleteModal";
import moment from "moment";
import EventService from "../../services/event.service";
import Toaster from "../../components/ui/toaster";
import Loader from "../../assets/images/loader.gif";

const status = ["Accept", "Pending", "Reject"];

const DashboardTable = ({
  data,
  totalData,
  page,
  setPage,
  limit,
  setLimit,
  getAllUser,
  isLoading,
}) => {
  const keys = Object.keys(data);
  console.log("🚀  keys:", keys);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const navigate = useNavigate();

  const handleChange = (type, page) => {
    const total = totalData / limit;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page !== newPage) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page !== 1) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      if (page !== 1) {
        setPage(1);
      }
    } else if (type === "Last") {
      if (page !== newPage) {
        setPage(newPage);
      }
    } else {
      setPage(page);
    }
  };

  const handleDataPerPage = (e) => {
    setLimit(e);
  };

  const handalUpdate = (type, id) => {
    if (type === "edit") {
      navigate(`/events/${id}`);
    } else {
      navigate(`/events/view/${id}`);
    }
  };

  // Delete Data
  const handleDelete = async (id) => {
    try {
      const response = await EventService.deleteEvent(id);
      getAllUser();
      Toaster("success", "Event Deleted Successfully");
    } catch (error) {
      console.log("🚀 ~ handleDelete ~ error:", error);
    }
  };

  return (
    <>
      <div className="border rounded-md">
        <Table>
          <TableHeader>
            <TableRow className="whitespace-nowrap">
              <TableHead className="w-[60px] text-center">No.</TableHead>
              <TableHead className="">Age 16 - 18</TableHead>
              <TableHead>Age 18 - 20</TableHead>
              <TableHead>Age 20 - 22</TableHead>
              <TableHead>Age 22 - 24</TableHead>
              <TableHead className="">Age 24 - 30</TableHead>
              <TableHead className="">Age 30+</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={8} className="text-center">
                  <div className="flex justify-center items-center h-full">
                    <img src={Loader} alt="Loading..." className="w-12 h-12" />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {keys.length > 0 ? (
                  <>
                    {new Array(1).fill().map((invoices, index) => (
                      <TableRow key={index}>
                        <TableCell className="text-center">1</TableCell>
                        <TableCell className="min-w-40 px-10">
                          {data?.age16TO18}
                        </TableCell>
                        <TableCell className="min-w-40 px-10">
                          {data?.age18TO20}
                        </TableCell>
                        <TableCell className="min-w-40 px-10">
                          {data?.age20TO22}
                        </TableCell>
                        <TableCell className="min-w-40 px-10">
                          {data?.age22TO24}
                        </TableCell>
                        <TableCell className="min-w-40 px-10">
                          {data?.age24TO30}
                        </TableCell>
                        <TableCell className="min-w-40 px-10">
                          {data?.age30UP}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} className="text-center">
                      <div className="flex justify-center items-center h-full text-muted-foreground font-bold">
                        Data not Found
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </div>
      {data?.length > 0 && (
        <div className="mt-5">
          <Pagination
            total={totalData}
            page={page}
            handleChange={handleChange}
            dataPerPage={limit}
            handleDataPerPage={handleDataPerPage}
            PAGE_SIZES={PAGE_SIZES}
          />
        </div>
      )}
    </>
  );
};

export default DashboardTable;
