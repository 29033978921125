import React, { useState } from "react";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { Label } from "../../components/ui/label";
import Logo from "../../assets/images/logo.svg";
import BackgroundOne from "../../assets/images/login-bg-1.svg";
import BackgroundTwo from "../../assets/images/login-bg-2.svg";
import Eye from "../../assets/images/eye.svg";
import RiEyeCloseFill from "../../assets/images/eye-close.svg";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import Toaster from "../../components/ui/toaster";
import { login, setLoggedIn, setToken } from "../../store/slice/auth";
import { useDispatch } from "react-redux";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = (showPassword) => {
    setShowPassword(showPassword);
  };

  const handleSubmit = async (values, action) => {
    setLoading(true);
    try {
      const { email, password } = values;
      const response = await dispatch(login({ email, password })).unwrap();

      dispatch(setLoggedIn(true));
      console.log("response", response);

      dispatch(setToken(response?.data?.token));
      localStorage.setItem("isLoggedIn", JSON.stringify(true));

      if (response?.data?.statusCode === 200) {
        navigate("/dashboard");
        action.resetForm();
        window.location.reload();
      }
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
      Toaster("error", error.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <div className="max-w-[400px] shadow-login relative w-full">
        {/* Befor Image */}
        <div className="absolute -z-10 max-tab:left-0 tab:-right-[50px] -top-[42px]">
          <img src={BackgroundOne} alt="Background" className="w-full h-full" />
        </div>
        <div className="p-4 lg:p-6 rounded-lg relative bg-white z-10 grid gap-6">
          <div className="py-6 tab:pb-10 flex justify-center">
            <img src={Logo} alt="Logo" />
          </div>
          <div>
            <h3 className="text-title font-bold text-2xl mb-2 text-center">
              Welcome to Raver!
            </h3>
            <p className="text-black/55 font-normal text-center">
              Please sign-in to your account
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div className="my-6 grid gap-4">
                <div className="grid gap-2">
                  <Label className="uppercase font-semibold text-sm">
                    Email
                  </Label>
                  <Input
                    type="email"
                    id="email"
                    placeholder="Email"
                    name="email"
                    onChange={formik.handleChange}
                    values={formik.values.email}
                  />
                </div>
                <div className="grid gap-2">
                  <div className="flex items-center gap-3 justify-between">
                    <Label className="uppercase font-semibold text-sm">
                      Password
                    </Label>
                  </div>
                  <div className="relative">
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      className="pr-10"
                      name="password"
                      onChange={formik.handleChange}
                      values={formik.values.password}
                    />
                    <div className="absolute right-[14px] top-1/2 -translate-y-1/2 cursor-pointer">
                      {showPassword ? (
                        <img
                          src={RiEyeCloseFill}
                          className="h-5 w-5"
                          alt="Eye"
                          onClick={() => togglePasswordVisibility(false)}
                        />
                      ) : (
                        <img
                          src={Eye}
                          className="h-5 w-5"
                          alt="Eye"
                          onClick={() => togglePasswordVisibility(true)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <Button
                  className="w-full font-medium text-base"
                  type="submit"
                  onClick={formik.handleSubmit}
                >
                  Sign In
                </Button>
              </div>
            </form>
          </div>
        </div>
        {/* Befor Image */}
        <div className="absolute -z-0 max-tab:right-0 tab:-left-[50px] -bottom-12 tab:-bottom-[43px]">
          <img src={BackgroundTwo} alt="Background" className="w-full h-full" />
        </div>
      </div>
    </div>
  );
};

export default Login;
