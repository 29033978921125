import ServerCall from "../serverCall";

const serverCall = ServerCall();

const getAllUser = (body) => {
  const response = serverCall.customAxios.post(`/admin/getUser`, body);
  return response;
};

const getUserDetails = (id) => {
  const response = serverCall.customAxios.post(
    `/admin/getUserDetails?id=${id}`
  );
  return response;
};

const UserService = { getAllUser, getUserDetails };

export default UserService;
