import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "./ui/dialog";
import { OctagonAlert } from "lucide-react";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Button } from "./ui/button";

const DeleteModal = ({ content, handleDelete, id }) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="h-7 w-7 border border-destructive hover:bg-destructive cursor-pointer flex items-center justify-center rounded-sm duration-200 group">
          <RiDeleteBin6Fill
            size={18}
            className="text-destructive group-hover:text-white duration-200"
          />
        </div>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogDescription className="flex items-center justify-center">
            <OctagonAlert className="text-destructive h-14 w-14" />
          </DialogDescription>
          <DialogTitle className="text-center pt-4">Are You Sure?</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <DialogDescription className="text-center">
            Are you sure you want to delete <strong> {content}</strong> ?
          </DialogDescription>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="outline" className="w-full">
              Cancel
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              type="submit"
              variant="destructive"
              className="w-full"
              onClick={() => handleDelete(id)}
            >
              Delete
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteModal;
