import { Card } from "../../components/ui/card";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import React, { useEffect, useState } from "react";
import Paginations from "../../components/Paginations";
// import EventService from "../../services/report.service";
import ReportService from "../../services/report.service";
import Toaster from "../../components/ui/toaster";
// const reportData = [
//   {
//     id: 1,
//     name: "Mitesh Makwana",
//     count: "16",
//     reportedBy: "Ashish Kakadiya",
//     description: [
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//     ],
//   },
//   {
//     id: 2,
//     name: "Ashish Kakadiya",
//     reportedBy: "Fenil Desai",
//     count: "15",
//     description: [
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//     ],
//   },
//   {
//     id: 3,
//     name: "Fenil Desai",
//     reportedBy: "Rushikesh Bhayani",
//     count: "9",
//     description: [
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//     ],
//   },
//   {
//     id: 4,
//     name: "Rushikesh Bhayani",
//     reportedBy: "Prince Satasiya",
//     count: "8",
//     description: [
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//     ],
//   },
//   {
//     id: 5,
//     name: "Prince Satasiya",
//     reportedBy: "Mitesh Makwana",
//     count: "5",
//     description: [
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//     ],
//   },
// ];

const EventReport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const PAGE_SIZES = [10, 20, 30, 50, 100];

  const getAllReport = async () => {
    setIsLoading(true);
    try {
      const body = {
        page,
        size: limit,
        search,
      };
      const response = await ReportService.getReport(body);
      const reports = response.data.data;
      console.log("responseeee", response.data.data);

      setReportData(reports);
      setTotalData(response.data.total);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching reports:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllReport();
  }, [page, limit, search]);

  const handleChange = (type, page) => {
    const total = totalData / limit;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page !== newPage) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page !== 1) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      if (page !== 1) {
        setPage(1);
      }
    } else if (type === "Last") {
      if (page !== newPage) {
        setPage(newPage);
      }
    } else {
      setPage(page);
    }
  };

  const handleDataPerPage = (e) => {
    setLimit(e);
    // setPage(1);
  };
  console.log("rep daatt", reportData);

  const handleClick = async (isActive, id) => {
    if (isActive) {
      try {
        const response = await ReportService?.blockUser(id);
        if (response) {
          Toaster("success", "User block successfully");
          getAllReport();
        }
      } catch (error) {
        Toaster("error", error?.response?.data?.message);
      }
    } else {
      try {
        const response = await ReportService?.unBlockUser(id);
        if (response) {
          Toaster("success", "User unBlock successfully");
          getAllReport();
        }
      } catch (error) {
        Toaster("error", error?.response?.data?.message);
      }
    }
  };
  return (
    <div>
      {/* <Card>
        <Accordion type="single" collapsible className="w-full">
          {reportData.map((item, index) => (
            <AccordionItem value={item.id} key={index}>
              <AccordionTrigger className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-12">
                <p className="text-start sm:col-span-1 md:col-span-3">
                  {item.name}
                </p>
                <p className="text-start sm:col-span-1 md:col-span-3">
                  {item.reportedBy}
                </p>
                <p className="text-start md:text-center sm:col-span-1 md:col-span-2">
                  <span className="bg-primary px-4 py-0.5 rounded-3xl font-semibold">
                    {item.count}
                  </span>
                </p>
                <p className="text-start sm:col-span-1 md:col-span-3">
                  {item.name}
                </p>
                <p className="hidden sm:flex md:hidden"></p>
              </AccordionTrigger>
              <AccordionContent className="relative">
                <div className="">
                  <span className="bg-primary px-4 py-0.5 rounded-3xl absolute -top-4 right-4 font-semibold">
                    {item.count}
                  </span>
                  <div className="mt-5 grid gap-2">
                    <div className="flex items-start gap-4">
                      <h3 className="font-semibold flex items-start gap-4 !w-32">
                        Description :
                      </h3>
                    </div>
                    {item.description?.map((desc, index) => (
                      <p className="w-full" key={index}>
                        <span className="font-bold">{index + 1}.</span>{" "}
                        <span>{desc}</span>
                        {item.description.length > index + 1 && (
                          <div className="border-b border-gray-300 pt-2"></div>
                        )}
                      </p>
                    ))}
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
        <div className="mt-5">
        <Paginations
            total={totalData}
            page={page}
            handleChange={handlePageChange}
            dataPerPage={limit}
            handleDataPerPage={handleDataPerPage}
            PAGE_SIZES={[5, 10, 15]}  // Adjust page sizes as needed
          />
        </div>
      </Card> */}
      <Card>
        <Accordion type="single" collapsible className="w-full">
          {reportData.length > 0 ? (
            reportData.map((item, index) => (
              <AccordionItem value={item?.reportedUser?._id} key={index}>
                <AccordionTrigger className="">
                  <div className="w-full flex items-center justify-between gap-5 pr-5 tab:pr-10">
                    <div className="flex max-tab:flex-col max-tab:items-start items-center tab:gap-10 gap-2">
                      <img
                        src={item?.reportedUser?.mainImgUrl}
                        alt="img"
                        width={50}
                        height={50}
                        className="rounded-full"
                      />
                      <p className="text-left tab:min-w-48">
                        {item?.reportedUser?.name}
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p
                        className="text-start md:text-center"
                        onClick={() =>
                          handleClick(
                            item.reportedUser?.isActive,
                            item?.reportedUser?._id
                          )
                        }
                      >
                        <span className="bg-primary px-5 py-1 rounded-3xl font-semibold">
                          {item.reportedUser?.isActive ? "Block" : "Unblock"}
                        </span>
                      </p>
                      <p className="text-start md:text-center">
                        <span className="bg-primary px-4 py-0.5 rounded-3xl font-semibold">
                          {item.reportUserCount}
                        </span>
                      </p>
                    </div>
                    {/* <p className="text-start sm:col-span-1 md:col-span-3">
                    {item.name}
                  </p> */}
                    {/* <p className="hidden sm:flex md:hidden"></p> */}
                  </div>
                </AccordionTrigger>
                <AccordionContent className="relative">
                  <div className="">
                    {/* <span className="bg-primary px-4 py-0.5 rounded-3xl absolute -top-4 right-4 font-semibold">
                      {item.reportUserCount}
                    </span> */}
                    <div className="mt-5 grid gap-2">
                      <div className="flex items-start gap-4">
                        <h3 className="font-semibold flex items-start gap-4 !w-32">
                          Description :
                        </h3>
                      </div>
                      {item.reportUser.length > 0 ? (
                        <>
                          {item.reportUser?.map((desc, index) => (
                            <p className="w-full" key={index}>
                              <span className="font-bold">{index + 1}.</span>{" "}
                              <span className="font-bold">{desc.name}</span>
                              <div className="flex gap-1">
                                <p className="font-bold">Text: </p>
                                <span>{desc.text}</span>
                              </div>
                              {item.reportUser.length > index + 1 && (
                                <div className="border-b border-gray-300 pt-2"></div>
                              )}
                            </p>
                          ))}
                        </>
                      ) : (
                        <div>
                          <p>Data Not found</p>
                        </div>
                      )}

                      {item.text}
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))
          ) : (
            <p className="text-center text-gray-500">No reports available.</p>
          )}
        </Accordion>

        <div className="mt-5">
          {/* <Paginations
            total={totalData}
            page={page}
            handleChange={handleChange}
            dataPerPage={limit}
            handleDataPerPage={handleDataPerPage}
            PAGE_SIZES={PAGE_SIZES}
            data={data}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            isLoading={isLoading}
          /> */}
          <Paginations
            total={totalData}
            page={page}
            handleChange={handleChange}
            dataPerPage={limit}
            handleDataPerPage={handleDataPerPage}
            PAGE_SIZES={PAGE_SIZES}
            isLoading={isLoading}
          />
        </div>
      </Card>
    </div>
  );
};

export default EventReport;
