import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import React from "react";

const Account = () => {
  return (
    <div>
      <Tabs defaultValue="account" className="w-[400px]">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="account">Account</TabsTrigger>
          <TabsTrigger value="password">Password</TabsTrigger>
        </TabsList>
        <TabsContent value="account">
          <Card>
            <div>
              <CardTitle className="mb-5">Account</CardTitle>
              <CardDescription className="mb-5">
                Make changes to your account here. Click save when you're done.
              </CardDescription>
            </div>
            <div className="space-y-2">
              <div className="space-y-1">
                <Label htmlFor="name" className="uppercase font-medium text-sm">
                  Name
                </Label>
                <Input id="name" placeholder="Enter Name" />
              </div>
              <div className="space-y-1">
                <Label
                  htmlFor="username"
                  className="uppercase font-medium text-sm"
                >
                  Email
                </Label>
                <Input id="username" placeholder="Enter Email" />
              </div>
            </div>
            <div className="mt-5">
              <Button className="text-base">Save changes</Button>
            </div>
          </Card>
        </TabsContent>
        <TabsContent value="password">
          <Card>
            <div>
              <CardTitle className="mb-5">Password</CardTitle>
              <CardDescription className="mb-5">
                Change your password here. After saving, you'll be logged out.
              </CardDescription>
            </div>
            <div className="space-y-2">
              <div className="space-y-1">
                <Label
                  htmlFor="current"
                  className="uppercase font-medium text-sm"
                >
                  Current password
                </Label>
                <Input id="current" type="password" />
              </div>
              <div className="space-y-1">
                <Label htmlFor="new" className="uppercase font-medium text-sm">
                  New password
                </Label>
                <Input id="new" type="password" />
              </div>
            </div>
            <div className="mt-5">
              <Button className="text-base">Save password</Button>
            </div>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Account;
